import { createSlice } from "@reduxjs/toolkit";
import { TState } from "../types";

const initialState: TState["errors"] = {};

const errorsSlice = createSlice({
  name: "errors",
  initialState,
  reducers: {
    setError: (state, { payload }) => {
      state.error = payload;
    },
    clearError: (state) => {
      state.error = null;
    },
  },
});

export const errorsReducer = errorsSlice.reducer;
export const errorsActions = errorsSlice.actions;

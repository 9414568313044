import { createSlice } from "@reduxjs/toolkit";
import { LOADING_STATUS } from "../../constants";
import { fetchVsGroupList } from "../thunk/cameras";
import { TState } from "../types";

const initialState: TState["cameras"] = {
  status: null,
  error: null,
  cameras: null,
};

const camerasSlice = createSlice({
  name: "cameras",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchVsGroupList.fulfilled, (state, { payload }) => {
      state.status = LOADING_STATUS.RESOLVER;
      state.cameras = payload;
      return;
    });
    builder.addCase(fetchVsGroupList.rejected, (state, { payload }) => {
      state.status = LOADING_STATUS.REJECTED;
      state.error = `Ошибка загрузки камер: ${payload}`;
    });
    builder.addCase(fetchVsGroupList.pending, (state) => {
      state.status = LOADING_STATUS.LOADING;
      state.error = null;
    });
  },
});

export const camerasReducer = camerasSlice.reducer;
export const camerasActions = camerasSlice.actions;

const ENV = process.env.REACT_APP_ENV || "prod";

const API_URL_PROD = process.env.REACT_APP_SERVER_PROD;
const API_URL_DEV = process.env.REACT_APP_SERVER_DEV;

export const VERSION = "v1";

export const API_URL = ENV === "prod" ? API_URL_PROD : API_URL_DEV;

export const API_PREFIX = {
  AUTH: "auth",
  USERS: "users",
  VIDEO_SOURCE: "video_source",
  VIDEO_SOURCE_GROUP: "video_source_group",
} as const;

export const AUTH_URL = `${API_URL}/${VERSION}/${API_PREFIX.AUTH}`;
export const USERS_URL = `${API_URL}/${VERSION}/${API_PREFIX.USERS}`;

const Error = () => (
  <svg
    width="72"
    height="73"
    viewBox="0 0 72 73"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.5" width="72" height="72" rx="36" fill="#FEE4E2" />
    <path
      d="M36 30.5V36.5M36 42.5H36.015M51 36.5C51 44.7843 44.2843 51.5 36 51.5C27.7157 51.5 21 44.7843 21 36.5C21 28.2157 27.7157 21.5 36 21.5C44.2843 21.5 51 28.2157 51 36.5Z"
      stroke="#F04438"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Error;

import CameraFocus from "../../../assets/icons/camera-focus";
import Camera02 from "../../../assets/icons/camera-02";
import Database01 from "../../../assets/icons/database-01";
import Life01 from "../../../assets/icons/life-buoy-01";
import Sale01 from "../../../assets/icons/sale-01";
import HomeSmile from "../../../assets/icons/home-smile";
import Plus from "../../../assets/icons/plus";

export const EXTRA_MENU_ITEMS = [
  {
    id: "support",
    title: "Поддержка",
    icon: <Life01 />,
    path: "/home/support",
  },
  {
    id: "subscription",
    title: "Подписка",
    icon: <Sale01 />,
    path: "/home/subscription",
    disabled: true,
  },
];

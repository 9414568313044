import React, { memo } from "react";
import TitleContainer from "../../../../components/TitleContainer/TitleContainer";
import camera from "../../../../assets/images/camera.png";
import Input from "../../../../ui/Input/Input";
import FooterButtons from "../FooterButtons/FooterButtons";
import { TSetStep } from "../CameraAddition/CameraAddition";
const ForthStep = memo(
  ({
    cameraName,
    setCameraName,
    setStep,
  }: {
    cameraName: string;
    setCameraName: React.Dispatch<React.SetStateAction<string>>;
    setStep: TSetStep;
  }) => {
    return (
      <>
        <TitleContainer
          title="Почти все готово!"
          description="Придумайте название для камеры. "
          size="medium"
        />
        <div className="flex items-center justify-between gap-3">
          <div>
            <Input
              placeholder="Название камеры"
              label="Название камеры"
              id="camera-name"
              value={cameraName}
              onChange={setCameraName}
              description="Допускаются буквенные и численные значения, максимальная длина 30 символов."
            />
          </div>
          <img src={camera} alt="camera" className="w-50" />
        </div>
        <FooterButtons
          titleNext="Добавить камеру"
          titlePrev="Вернуться назад"
          prevOnClick={() => setStep(3)}
          nextOnClick={() => {
            setStep(5);
          }}
          disabledNext={!cameraName}
          noInfo
        />
      </>
    );
  },
);

export default ForthStep;

import { TVideoSourceGroup } from "./api/types/videoSourceGroups";

export const INPUT_TYPES = {
  TEXT: "text",
  TEL: "tel",
  EMAIL: "email",
  PASSWORD: "password",
  NUMBER: "number",
} as const;

export const INVALID_INPUT_TEXTS = {
  PASSWORD: "Пароль должен содержать не менее 8 символов.",
  MATCH_PASSWORD: "Пароли должны совпадать",
  NOT_FOUND_EMAIL: "Аккаунт не найден.",
  EMAIL: "Email должен содержать @",
};
// todo rewrite it on enum ts
export const COMMON_ERROR_TEXT = {
  "Network Error": "Сервер недоступен. Попробуйте позже.",
  "Request failed with status code 400": "Некорректные данные.",
  "Request failed with status code 422": "Некорректные данные.",
  ERR_BAD_REQUEST: "Некорректные данные.",
  REGISTER_USER_ALREADY_EXISTS: "Такой пользователь уже существует.",
  "Not Found": "Пользователь не найден.",
  LOGIN_BAD_CREDENTIALS: "Неверный логин или пароль.",
} as const;

export enum LOADING_STATUS {
  LOADING = "loading",
  REJECTED = "error",
  RESOLVER = "resolved",
}

export const mockVSGroups: TVideoSourceGroup[] = [
  {
    id: "1",
    title: "Группа 1",
    video_sources: [
      { id: 1, image: "" },
      { id: 2, image: "" },
      { id: 3, image: "" },
      { id: 4, image: "" },
    ],
  },
  {
    id: "2",
    title: "Группа 2",
    video_sources: [
      { id: 1, image: "" },
      { id: 2, image: "" },
      { id: 3, image: "" },
      { id: 4, image: "" },
    ],
  },
  {
    id: "3",
    title: "Группа 3",
    video_sources: [
      { id: 1, image: "" },
      { id: 2, image: "" },
      { id: 3, image: "" },
      { id: 4, image: "" },
    ],
  },
  {
    id: "4",
    title: "Группа 4",
    video_sources: [
      { id: 1, image: "" },
      { id: 2, image: "" },
      { id: 3, image: "" },
      { id: 4, image: "" },
    ],
  },
  {
    id: "5",
    title: "Группа 5",
    video_sources: [
      { id: 1, image: "" },
      { id: 2, image: "" },
      { id: 3, image: "" },
      { id: 4, image: "" },
    ],
  },
];

const Wifi = (props) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 29.25H18.015M34.2096 13.0511C29.9393 9.13799 24.2484 6.75 17.9998 6.75C11.7512 6.75 6.06035 9.13799 1.79004 13.0511M7.0979 18.3645C10.0051 15.8035 13.8211 14.25 18 14.25C22.1788 14.25 25.9948 15.8035 28.902 18.3645M23.5475 23.6627C22.0188 22.4644 20.0928 21.75 17.9998 21.75C15.8752 21.75 13.9226 22.4862 12.3831 23.7174"
      stroke="#17B26A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    />
  </svg>
);

export default Wifi;

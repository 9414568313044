import useHttp from "./api";
import { API_PREFIX } from "./constants";
import {
  TUngroupedVideoSource,
  TVideoSourceGroup,
} from "./types/videoSourceGroups";

export default class VideoSourceGroupApi {
  static http = useHttp(API_PREFIX.VIDEO_SOURCE_GROUP);

  static async getListVideoSourceGroups(): Promise<TVideoSourceGroup[]> {
    const { data } = await VideoSourceGroupApi.http.get(`/`);
    return data;
  }
  static async createVideoSourceGroup(
    videoGroupData: TVideoSourceGroup["title"],
  ) {
    const { data } = await VideoSourceGroupApi.http.post(`/`, videoGroupData);
    return data;
  }
  static async getVideoSourceGroupById(
    videoSourceId: TVideoSourceGroup["id"],
  ): Promise<TVideoSourceGroup> {
    const { data } = await VideoSourceGroupApi.http.get(`/${videoSourceId}`);
    return data;
  }
  static async getVSGroupsAndUngroupedVS(): Promise<TUngroupedVideoSource> {
    const { data } = await VideoSourceGroupApi.http.get(`/`);
    return data;
  }

  static async updateVideoSourceGroup(
    videoSourceId: TVideoSourceGroup["id"],
    title: TVideoSourceGroup["title"],
  ): Promise<TVideoSourceGroup> {
    const { data } = await VideoSourceGroupApi.http.patch(`/${videoSourceId}`, {
      title,
    });
    return data;
  }

  static async deleteVideoSourceGroup(
    videoSourceId: TVideoSourceGroup["id"],
  ): Promise<TVideoSourceGroup> {
    const { data } = await VideoSourceGroupApi.http.delete(`/${videoSourceId}`);
    return data;
  }
}

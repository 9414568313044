import { FC, ReactNode } from "react";
import { classNames } from "../../../../utils/classNames";
import s from "./NavList.module.css";
import { NavLink } from "react-router-dom";
import cls from "../Sidebar/Sidebar.module.css";
type TMenuItem = {
  id: string;
  title: string;
  path: string;
  icon: ReactNode;
  disabled?: boolean;
  buttonIcon?: ReactNode;
  buttonOnClick?: () => void;
};

interface TNavList {
  menuItems: TMenuItem[];
  title: string;
}
const NavList: FC<TNavList> = ({ menuItems, title }) => {
  return (
    <div className={s.navContainer}>
      {/* <p className={cls.navTitle}>{title}</p> */}
      <ul className={s.navList}>
        {menuItems.map((item) => (
          <li key={item.id} className={s.navItem}>
            <NavLink
              to={item.path}
              className={({ isActive }) =>
                classNames(s.navLink, {
                  [s.activeLink]: isActive,
                  [s.disabled]: item.disabled,
                })
              }
              onClick={(e) => item.disabled && e.preventDefault()}
              end
            >
              {item.icon}
              <span className="primary-text-s text-gray-600">{item.title}</span>
              {item.buttonIcon && (
                <button
                  className="ml-auto p-0 invisible"
                  onClick={item.buttonOnClick}
                >
                  {item.buttonIcon}
                </button>
              )}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NavList;

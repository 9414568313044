import XClose from '../../assets/icons/x-close';
import { classNames } from '../../utils/classNames';
import {
  ModalContainer,
  TModalContainer,
} from '../ModalContainer/ModalContainer';
import s from './Modal.module.css';

interface TModal extends TModalContainer {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  headerComponent?: React.ReactNode;
  width?: string;
}
export const Modal = ({
  onClose,
  open,
  children,
  headerComponent,
  width = '50%',
}: TModal) => {
  return (
    <ModalContainer onClose={onClose} open={open}>
      <div
        onClick={(e) => e.stopPropagation()}
        className={classNames(s.container)}
        style={{ width }}
      >
        <header className={s.header}>
          {headerComponent}
          <button className={s.cross} onClick={onClose}>
            <XClose />
          </button>
        </header>
        <div className="divider" />
        <div className={s.content}>{children}</div>
      </div>
    </ModalContainer>
  );
};

import { memo, useMemo, useRef, useState } from "react";
import { classNames } from "../../utils/classNames";
import s from "./Input.module.css";
import EyeOff from "../../assets/icons/eye-off";
import Eye from "../../assets/icons/eye";
import { INPUT_TYPES } from "../../constants";
import Button from "../Button/Button";
import AlertCircle from "../../assets/icons/alert-circle";

type TInput = {
  id: string;
  placeholder: string;
  value: string;
  onChange: React.Dispatch<React.SetStateAction<string>>;
  type?: (typeof INPUT_TYPES)[keyof typeof INPUT_TYPES];
  invalidText?: string;
  onBlur?: () => void;
  label?: string;
  autocomplete?: "off" | "on" | string;
  size?: "small" | "medium" | "large";
  description?: string;
  squared?: boolean;
  disabled?: boolean;
};
const Input: React.FC<TInput> = memo(
  ({
    id,
    placeholder,
    value,
    onChange,
    type = INPUT_TYPES.TEXT,
    invalidText,
    onBlur,
    label,
    autocomplete = "on",
    size = "large",
    description,
    squared = false,
    disabled,
  }) => {
    const [isPassShown, setIsPassShown] = useState<boolean>(false);
    const [isInputActive, setIsInputActive] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const inputType = useMemo(
      () =>
        type === INPUT_TYPES.PASSWORD
          ? isPassShown
            ? "text"
            : "password"
          : type,
      [isPassShown, type],
    );
    const handleInputFocus = () => setIsInputActive(true);

    const handleInputBlur = () => {
      onBlur && onBlur();
      setIsInputActive(false);
    };

    const handleContainerClick = (event: any) => {
      if (
        event.target.tagName !== "BUTTON" &&
        event.target.tagName !== "svg" &&
        event.target.tagName !== "path"
      ) {
        inputRef.current?.focus();
        setIsInputActive(true);
      }
    };
    return (
      <div className={s.container}>
        {label && (
          <label htmlFor={id} className={s.label}>
            {label}
          </label>
        )}
        <div
          className={classNames(s.inputContainer, {
            [s.invalid]: invalidText,
            [s.active]: isInputActive,
            [s[size]]: size,
            [s.squared]: squared,
            [s.disabled]: disabled,
          })}
          onClick={handleContainerClick}
        >
          <input
            ref={inputRef}
            id={id}
            type={inputType}
            value={value}
            placeholder={placeholder}
            onFocus={handleInputFocus}
            onChange={(e: any) =>
              onChange(type === "tel" ? e.target : e.target?.value)
            }
            onBlur={handleInputBlur}
            className={classNames(s.input, {
              [s[size]]: size,
              [s.squared]: squared,
            })}
            autoComplete={autocomplete}
            disabled={disabled}
          />
          {type === INPUT_TYPES.PASSWORD && (
            <Button
              color="transparent"
              size="xsmall"
              className={s.eyeButton}
              onClick={(e: any) => {
                e.preventDefault();
                setIsPassShown((prev) => !prev);
              }}
              disabled={disabled}
            >
              {isPassShown ? <EyeOff /> : <Eye />}
            </Button>
          )}
        </div>

        {description && !invalidText ? (
          <p className={classNames(s.description, { [s[size]]: size })}>
            {description}
          </p>
        ) : (
          invalidText && (
            <div className="flex items-center gap-2">
              <AlertCircle />
              <span className={classNames(s.invalidText, { [s[size]]: size })}>
                {invalidText}
              </span>
            </div>
          )
        )}
      </div>
    );
  },
);

export default Input;

import React from 'react';
import s from './TitleContainer.module.css';

type TTitleContainer = {
  title: string;
  description?: string | React.ReactNode;
  size?: 'large' | 'medium' | 'small' | 'xsmall';
};

const TitleContainer = ({
  title,
  description,
  size = 'large',
}: TTitleContainer) => {
  return (
    <div className={s.container + ` ${s[size]}`}>
      <h1 className="h1">{title}</h1>
      <p className={s.p}>{description}</p>
    </div>
  );
};

export default TitleContainer;

import React, { memo } from "react";
import { useSelector } from "react-redux";
import { TState } from "../../../redux/types";
import { PageError } from "../../../components/PageError";

export const ErrorCatcher = memo(
  ({ children }: { children: React.ReactNode }): JSX.Element => {
    const error = useSelector((state: TState) => state.errors.error);
    if (error) {
      return <PageError error={error} />;
    }
    return <>{children}</>; // Если ошибок нет, то возвращаем children;
  },
);

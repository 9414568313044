import Button from "../../ui/Button/Button";
import s from "./PageError.module.css";
import Error from "../../assets/icons/error";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { errorsActions } from "../../redux/slices/errors";
import { classNames } from "../../utils/classNames";
export const PageError = ({ error }: { error?: string }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const goToMain = () => {
    navigate("/");
    dispatch(errorsActions.clearError());
  };
  return (
    <div className={classNames(s.wrapper, { [s.open]: error })}>
      <Error />
      <h1>Что-то пошло не так, сообщите нам о следующей ошибке</h1>
      <span className="text-gray-600">Error type: {error}</span>
      <div className={s.buttonGroup}>
        <Button color="white" onClick={goToMain}>
          Вернуться на главную
        </Button>
        <Button color="light-green" href="" tag="a">
          Сообщить об ошибке
        </Button>
      </div>
    </div>
  );
};

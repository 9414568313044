import useHttp from "./api";
import { API_PREFIX } from "./constants";
import { TVideoSource } from "./types/videoSource";

export default class VideoSourceApi {
  static http = useHttp(API_PREFIX.VIDEO_SOURCE);

  static async getListVideoSource(): Promise<TVideoSource[]> {
    const { data } = await VideoSourceApi.http.get(`/`);
    return data;
  }
  static async createVideoSource(videoData: TVideoSource) {
    const { data } = await VideoSourceApi.http.post(`/`, videoData);
    return data;
  }
  static async getVideoSourceById(
    videoSourceId: TVideoSource["id"],
  ): Promise<TVideoSource> {
    const { data } = await VideoSourceApi.http.get(`/${videoSourceId}`);
    return data;
  }

  static async updateVideoSource(
    videoSourceId: TVideoSource["id"],
    title: TVideoSource["title"],
  ): Promise<TVideoSource> {
    const { data } = await VideoSourceApi.http.patch(`/${videoSourceId}`, {
      title,
    });
    return data;
  }

  static async deleteVideoSource(
    videoSourceId: TVideoSource["id"],
  ): Promise<TVideoSource> {
    const { data } = await VideoSourceApi.http.delete(`/${videoSourceId}`);
    return data;
  }
}

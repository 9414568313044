import { Suspense } from "react";
import { Route } from "react-router-dom";
import Preloader from "../../ui/Preloader/Preloader";

type TLoading = {
  isContentPreloader?: boolean;
  children: JSX.Element;
};

export const Loading = ({ children, isContentPreloader }: TLoading) => (
  <Suspense fallback={<Preloader />}>
    {children}
  </Suspense>
);

export const getRoutePages = (pages: any, isContentPreloader?: boolean) =>
  pages.map(({ path, element }: any) => (
    <Route
      key={path}
      path={path}
      element={
        <Loading isContentPreloader={isContentPreloader} key={path}>
          {element}
        </Loading>
      }
    />
  ));
import TitleContainer from "../../../../components/TitleContainer/TitleContainer";
import Input from "../../../../ui/Input/Input";
import { INPUT_TYPES } from "../../../../constants";
import FooterButtons from "../FooterButtons/FooterButtons";
import useFetch from "../../../../hooks/useFetch";
import { TSetStep } from "../CameraAddition/CameraAddition";
import {
  TVideoSource,
  TVideoSourceProtocol,
  TVideoSourceSourceType,
} from "../../../../api/types/videoSource";
import { memo, useState } from "react";
import VideoSourceApi from "../../../../api/VideoSourceApi";

type TSecondStep = {
  // setPassword: React.Dispatch<React.SetStateAction<string>>;
  setStep: TSetStep;
  sourceType: TVideoSource["source_type"];
  setVideoSourceId: React.Dispatch<React.SetStateAction<string>>;
};
const SecondStep = memo(
  ({ setStep, setVideoSourceId, sourceType }: TSecondStep) => {
    const [ip, setIp] = useState<string>("");
    const [login, setLogin] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [port, setPort] = useState<string>("");
    const [path, setPath] = useState<string>("");
    const [protocol, setProtocol] = useState<string>("");

    const videoData: TVideoSource = {
      domain: ip,
      source_type: sourceType,
      protocol: protocol || TVideoSourceProtocol.HTTP,
      ...(login && { username: login }),
      ...(password && { password }),
      ...(port && { port: Number(port) }),
      ...(path && { path }),
    };
    const nextOnClick = async () => {
      const d = await VideoSourceApi.createVideoSource(
        videoData || {
          source_type: TVideoSourceSourceType.IP_CAMERA,
          protocol: TVideoSourceProtocol.HTTP,
          domain: "83.48.75.113",
          port: 8320,
          path: "/axis-cgi/mjpg/video.cgi",
        },
      );
      setVideoSourceId(d.id);

      setStep(3);
    };
    const { loading, error, fetchData, data, setError } = useFetch(
      nextOnClick,
      "camera addition",
    );

    const INPUTS = [
      {
        id: "IP",
        placeholder: "Введите IP-адрес",
        value: ip,
        onChange: setIp,
        label: "IP-адрес камеры",
        description:
          "Чтобы найти IP-адрес вашей камеры, проверьте руководство пользователя.",
        invalidText: error ? "Проверьте корректность IP-адреса камеры." : "",
        onBlur: () => setError(null),
      },
      {
        id: "port",
        placeholder: "Введите Порт",
        value: port,
        onChange: setPort,
        label: "Порт",
      },
      {
        id: "path",
        placeholder: "Введите Путь",
        value: path,
        onChange: setPath,
        label: "Путь",
      },
      {
        id: "protocol",
        placeholder: "Введите Протокол",
        value: protocol,
        onChange: setProtocol,
        label: "Протокол",
        description: "Протокол HTTP по умолчанию.",
      },
      {
        id: "ip-login",
        placeholder: "Введите логин",
        value: login,
        onChange: setLogin,
        label: "Логин",
      },
      {
        id: "ip-password",
        placeholder: "Введите пароль",
        value: password,
        onChange: setPassword,
        label: "Пароль",
        type: INPUT_TYPES.PASSWORD,
      },
    ];

    return (
      <>
        <TitleContainer
          title="Введите данные для подключения камеры"
          description="Чтобы настроить соединение с вашей IP-камерой введите следующие данные:"
          size="medium"
        />
        <form className="flex flex-col gap-4">
          {INPUTS.map((input) => (
            <Input
              id={input.id}
              key={input.id}
              label={input.label}
              value={input.value}
              onChange={input.onChange}
              placeholder={input.placeholder}
              type={input.type ?? INPUT_TYPES.TEXT}
              size="medium"
              description={input.description}
              squared
              invalidText={input.invalidText}
              onBlur={input.onBlur}
            />
          ))}
        </form>
        <FooterButtons
          titleNext="Продолжить"
          titlePrev="Вернуться назад"
          prevOnClick={() => setStep(1)}
          nextOnClick={fetchData}
          disabledNext={!ip || Boolean(error)}
          loadingNext={loading}
        />
      </>
    );
  },
);

export default SecondStep;

const ErrorCross = (props) => (
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="44" height="44" rx="22" fill="#F04438" />
    <path
      d="M26.1663 17.8335L17.833 26.1668M17.833 17.8335L26.1663 26.1668"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ErrorCross;

import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Sidebar } from "../../modules/Sidebar";
import s from "./PageLayout.module.css";
import { CameraAddition } from "../../modules/CameraAddition";
const PageLayout = () => {
  const navigate = useNavigate();

  const [preloader, setPreloader] = useState(true);

  const [openedAddCamera, setOpenedAddCamera] = useState(false);
  useEffect(() => {
    if (!localStorage.getItem("access_token")) {
      navigate("/");
    } else {
      setPreloader(false);
    }
  }, []);

  if (preloader) {
    return <div>Загрузка...</div>;
  }
  return (
    <div className="flex">
      <Sidebar setOpenedAddCamera={setOpenedAddCamera} />
      <div className={s.contentWrapper}>
        <Outlet />
      </div>
      {openedAddCamera && (
        <CameraAddition
          onClose={() => setOpenedAddCamera(false)}
          open={openedAddCamera}
        />
      )}
    </div>
  );
};

export default PageLayout;

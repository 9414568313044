const Wheel = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_493_8316)">
      <path
        d="M7.61363 7.61341L4.10752 4.1073M4.10752 15.8924L7.64006 12.3599M12.3843 12.3863L15.8904 15.8924M15.8904 4.1073L12.3574 7.64035M18.3334 9.99984C18.3334 14.6022 14.6025 18.3332 10.0001 18.3332C5.39771 18.3332 1.66675 14.6022 1.66675 9.99984C1.66675 5.39746 5.39771 1.6665 10.0001 1.6665C14.6025 1.6665 18.3334 5.39746 18.3334 9.99984ZM13.3334 9.99984C13.3334 11.8408 11.841 13.3332 10.0001 13.3332C8.15913 13.3332 6.66675 11.8408 6.66675 9.99984C6.66675 8.15889 8.15913 6.6665 10.0001 6.6665C11.841 6.6665 13.3334 8.15889 13.3334 9.99984Z"
        stroke="#98A1B4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_493_8316">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Wheel;

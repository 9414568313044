import s from "./Preloader.module.css";
import loader from "../../assets/images/loader.svg";
import { memo } from "react";
const Preloader = memo(
  ({ isContentPreloader = false }: { isContentPreloader?: boolean }) => (
    <div className={isContentPreloader ? s.wrapper : s.fullWrapper}>
      <div className={s.preloaderContainer}>
        <img src={loader} alt="preloader" className={s.preloader} />
      </div>
    </div>
  ),
);
export default Preloader;

import { memo, ReactNode } from "react";
import { classNames } from "../../utils/classNames";
import s from "./Button.module.css";
import Loader from "./icons/loader";
type TButton = {
  color: "green" | "white" | "red" | "transparent" | "light-green";
  children: JSX.Element | string | ReactNode;
  onClick?: (e?: any) => void;
  isLoading?: boolean;
  disabled?: boolean;
  isFull?: boolean;
  tag?: "button" | "a";
  href?: string;
  size?: "large" | "medium" | "small" | "xsmall";
  className?: string;
  actived?: boolean;
};

const BUTTON_LOADER_COLOR = {
  green: "white",
  white: "green",
  transparent: "green",
  red: "white",
  "light-green": "green",
} as const;

const Button = memo(
  ({
    color,
    onClick,
    isLoading,
    disabled,
    isFull,
    children,
    tag: Tag = "button",
    href,
    size = "large",
    className,
    actived,
  }: TButton) => {
    return (
      <Tag
        disabled={disabled}
        onClick={(e) => !isLoading && onClick && onClick(e)}
        className={classNames(
          `${s.button} ${s[color]} ${s[size]} ${className}`,
          {
            [s.w100]: isFull,
            [s.loading]: isLoading,
            [s.actived]: actived,
          },
        )}
        href={href}
      >
        {isLoading ? <Loader color={BUTTON_LOADER_COLOR[color]} /> : children}
      </Tag>
    );
  },
);

export default Button;

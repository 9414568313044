import { useMemo } from "react";
import ChevronRight from "../../../../assets/icons/chevron-right";
import s from "./Profile.module.css";
const radius = 23.5;
const totalSubscriptionDays = 30; // Общее количество дней подписки (например, месяц)
const circumference = 2 * Math.PI * radius; // Длина окружности

const Profile = () => {
  const daysRemaining = 29; // Количество дней подписки
  // Расчет заполненности дуги
  const offset = useMemo(
    () =>
      circumference - (daysRemaining / totalSubscriptionDays) * circumference,
    [daysRemaining],
  );
  return (
    <div className={s.profileContainer}>
      <div className={s.profileImgContainer}>
        <img
          src={"https://i.pravatar.cc/306"}
          alt="profile"
          className={s.profileImg}
          onError={() => null}
        />
        <svg width="50" height="50" className={s.svg}>
          <circle
            className="progress"
            cx="25"
            cy="25"
            r={radius}
            fill="transparent"
            stroke="var(--c-brand-500)"
            strokeWidth="1.5"
            strokeDasharray={circumference}
            strokeDashoffset={offset}
            transform="rotate(-90 25 25)"
          />
        </svg>
      </div>
      <div className={s.profileInfo}>
        <p className={s.profileName}>S3cR37_G4lumP</p>
        <p className={s.profileEmail}>m***@mail.ru</p>
      </div>
      <button className="ml-auto">
        <ChevronRight />
      </button>
    </div>
  );
};

export default Profile;

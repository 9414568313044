import { createAsyncThunk } from "@reduxjs/toolkit";
import VideoSourceGroupApi from "../../api/VideoSourceGroupApi";

export const fetchVsGroupList = createAsyncThunk(
  "cameras/fetchVsGroupList",
  async (_, { rejectWithValue }) => {
    try {
      const data = await VideoSourceGroupApi.getListVideoSourceGroups();
      return data;
    } catch (e: any) {
      const error = Array.isArray(e?.response?.data.detail)
        ? e.response?.data.detail[0]
        : e.response?.data.detail;

      return rejectWithValue(error);
    }
  },
);

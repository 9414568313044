const TooltipTriangle = (props) => (
  <svg
    width="16"
    height="9"
    viewBox="0 0 16 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.0711 0.485411C14.962 0.485411 15.4081 1.56255 14.7782 2.19252L8.70711 8.26359C8.31658 8.65412 7.68342 8.65412 7.29289 8.26359L1.22183 2.19252C0.591867 1.56255 1.03803 0.485411 1.92894 0.485411L14.0711 0.485411Z"
      fill="white"
      {...props}
    />
  </svg>
);

export default TooltipTriangle;

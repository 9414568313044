import "./App.css";
import { Route, Routes } from "react-router-dom";
import AuthLayout from "./layouts/AuthLayout/AuthLayout";
import "./bootstrap.css";
import { getRoutePages, Loading } from "./shared/routerConfig/utils";
import {
  CHILDREN_ROUTES,
  ROUTE_LAYOUTS,
} from "./shared/routerConfig/constants";
import PageLayout from "./layouts/PageLayout/PageLayout";
import { ErrorCatcher } from "./providers/ErrorCatcher";

function App() {
  return (
    <div className="App">
      {/* 
      <button
        onClick={async () => {
          const res = await AuthApi.logout();
          localStorage.removeItem("access_token");
          navigate("/");
        }}
      >
        logout
      </button> */}
      <ErrorCatcher>
        <Routes>
          <Route
            path="/"
            element={
              <Loading>
                <AuthLayout />
              </Loading>
            }
          >
            {getRoutePages(CHILDREN_ROUTES[ROUTE_LAYOUTS.AUTH])}
          </Route>
          <Route path="/home" element={<PageLayout />}>
            {getRoutePages(CHILDREN_ROUTES[ROUTE_LAYOUTS.CONTENT])}
          </Route>
        </Routes>
      </ErrorCatcher>
    </div>
  );
}

export default App;

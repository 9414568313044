import { createPortal } from 'react-dom';
import s from './ModalContainer.module.css';
import { classNames } from '../../utils/classNames';
import { useEffect, useState } from 'react';
export interface TModalContainer {
  onClose: () => void;
  open: boolean;
  children: React.ReactNode;
}

export const ModalContainer = ({
  onClose,
  open,
  children,
}: TModalContainer) => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    if (open) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [open]);
  const handleClose = () => {
    setIsVisible(false);
    setTimeout(() => {
      onClose();
    }, 300); // Задержка совпадает с продолжительностью анимации
  };
  return createPortal(
    <>
      <div
        className={classNames(s.modalContainer, { [s.open]: isVisible })}
        onClick={handleClose}
      >
        <div
          className={classNames(s.animated, { [s.animatedOpen]: isVisible })}
        >
          {children}
        </div>
      </div>
    </>,
    document.getElementById('root')!,
  );
};

import React, { memo, useEffect } from "react";
import TitleContainer from "../../../../components/TitleContainer/TitleContainer";
import camera from "../../../../assets/images/camera-02.png";
import loader from "../../../../assets/images/line-loader.svg";
import cloud from "../../../../assets/images/cloud.png";
import useFetch from "../../../../hooks/useFetch";
import ErrorCross from "../../../../assets/icons/error-cross";
import Success from "../../../../assets/icons/success";
import FooterButtons from "../FooterButtons/FooterButtons";
import VideoSourceApi from "../../../../api/VideoSourceApi";
import { TVideoSource } from "../../../../api/types/videoSource";
type TFifthStep = {
  onEnd: () => void;
  cameraName: TVideoSource["title"];
  videoSourceId: TVideoSource["id"];
};
const FifthStep = memo(({ onEnd, cameraName, videoSourceId }: TFifthStep) => {
  const updateCameraData = async () =>
    await VideoSourceApi.updateVideoSource(videoSourceId, cameraName); // update camera name
  const { error, loading, fetchData, data } = useFetch(
    updateCameraData,
    "camera settings",
  );
  useEffect(() => {
    fetchData();
  }, []);

  const titleData = error
    ? {
        title: "Возникла ошибка!",
        description: "Завершите процесс и начните заново.",
      }
    : !data || loading
      ? {
          title: "Почти все готово!",
          description: "Завершаем настройку камеры, пожалуйста, подождите..",
        }
      : {
          title: "Успешно завершено!",
          description: "Камера была успешно добавлена и настроена.",
        };

  const status = error ? (
    <ErrorCross />
  ) : !data || loading ? (
    <img src={loader} alt="loader" className="w-[20%]" />
  ) : (
    <Success />
  );
  return (
    <>
      <TitleContainer {...titleData} size="medium" />

      <div className="flex gap-3 items-center justify-between">
        <img src={camera} alt="camera-img" className="w-[35%]" />
        {status}
        <img src={cloud} alt="cloud-img" className="w-[35%]" />
      </div>
      <FooterButtons
        titleNext="Завершить"
        nextOnClick={onEnd}
        disabledNext={Boolean(loading || !data)}
      />
    </>
  );
});

export default FifthStep;

import TitleContainer from "../../../../components/TitleContainer/TitleContainer";
import s from "./ThirdStep.module.css";
import FooterButtons from "../FooterButtons/FooterButtons";
import { TSetStep } from "../CameraAddition/CameraAddition";
import { API_PREFIX, API_URL, VERSION } from "../../../../api/constants";
import { LS_ACCESS_TOKEN } from "../../../../constants/localstorage";
import { EventSourcePolyfill } from "event-source-polyfill";
import { memo, useEffect, useState } from "react";

type TThirdStep = {
  setStep: TSetStep;
  videoSourceId: string;
};

const ThirdStep = memo(({ setStep, videoSourceId }: TThirdStep) => {
  const [stream, setStream] = useState<string | null>(null);
  useEffect(() => {
    setTimeout(() => {
      // Задержка совпадает с продолжительностью анимации
      setStream("closed");
    }, 90000);
  }, []);

  useEffect(() => {
    const authorizationToken = localStorage.getItem(LS_ACCESS_TOKEN);
    let eventSource = new EventSourcePolyfill(
      `${API_URL}/${VERSION}/${API_PREFIX.VIDEO_SOURCE}/stream_video/${videoSourceId}`,
      {
        headers: {
          Authorization: "Bearer " + authorizationToken,
          // withCredentials: "true",
        },
      },
    );
    eventSource.onmessage = function (event) {
      setStream(event.data);
    };

    eventSource.onerror = (error) => {
      console.error("Error sse:", error);
      // eventSource.close();
    };
    return () => {
      eventSource.close(); // Закрываем EventSource при выходе из компонента
    };
  }, []);

  return (
    <>
      <TitleContainer
        title="Проверка камеры"
        description="Убедитесь, что камера настроена правильно и предоставляет четкое и ясное изображение."
        size="medium"
      />
      {!stream ? (
        <p className={s.banner}>Подготовка камеры...</p>
      ) : stream === "closed" ? (
        <p className={s.banner}>Стрим окончен</p>
      ) : (
        <img
          className={s.camera}
          src={`data:video/mp4;base64, ${stream}`}
          alt="video"
        />
      )}

      <FooterButtons
        titleNext="Продолжить"
        titlePrev="Вернуться назад"
        prevOnClick={() => setStep(2)}
        nextOnClick={() => setStep(4)}
      />
    </>
  );
});

export default ThirdStep;

import Logo from "../../../../assets/icons/logo";
import s from "./Sidebar.module.css";
import NavList from "../NavList/NavList";
import { EXTRA_MENU_ITEMS } from "../../constants/menuItems";
import Tooltip from "../../../../ui/Tooltip/Tooltip";
import Button from "../../../../ui/Button/Button";
import Profile from "../Profile/Profile";
import { FC } from "react";
import HomeSmile from "../../../../assets/icons/home-smile";
import Camera02 from "../../../../assets/icons/camera-02";
import CameraFocus from "../../../../assets/icons/camera-focus";
import Database01 from "../../../../assets/icons/database-01";
import Plus from "../../../../assets/icons/plus";

interface TSidebarProps {
  setOpenedAddCamera: (opened: boolean) => void;
}
export const Sidebar: FC<TSidebarProps> = ({ setOpenedAddCamera }) => {
  const MENU_ITEMS = [
    {
      id: "home",
      title: "Главная страница",
      icon: <HomeSmile />,
      path: "/home",
      // disabled: true,
    },
    {
      id: "cameras",
      title: "Камеры",
      icon: <Camera02 />,
      path: "/home/cameras",
      buttonIcon: <Plus />,
      buttonOnClick: () => setOpenedAddCamera(true),
    },
    {
      id: "models",
      title: "Модели",
      icon: <CameraFocus />,
      path: "/home/models",
    },
    {
      id: "data set",
      title: "Набор данных",
      icon: <Database01 />,
      path: "/home/data-set",
    },
  ];
  return (
    <div className={s.sidebar}>
      <div className={s.sidebarHeader}>
        <Logo />
      </div>
      <div className={s.contentContainer}>
        <div className={s.wrapper}>
          <NavList menuItems={MENU_ITEMS} title="Основное" />
          <div className="divider mx-4 my-3" />
          <NavList menuItems={EXTRA_MENU_ITEMS} title="Дополнительное" />
        </div>
        <footer className={s.sidebarFooter}>
          <div className="px-4">
            <Tooltip
              title="Navigation Items"
              description={`
            Offers a simple and clear way to navigate the application, 
            allowing users to quickly switch between different pages 
            or modules.`}
              color="white"
              width="100%"
            >
              <Button color="light-green" size="small">
                Upgrade
              </Button>
            </Tooltip>
          </div>
          <Profile />
        </footer>
      </div>
    </div>
  );
};

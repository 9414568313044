import axios from "axios";
import { API_PREFIX, API_URL, VERSION } from "./constants";
import { refresh } from "./refresh";

type TApiPrefix = (typeof API_PREFIX)[keyof typeof API_PREFIX];
const useHttp = (prefix: TApiPrefix) => {
  const $api = axios.create({
    baseURL: `${API_URL}/${VERSION}/${prefix}/`,
    withCredentials: true,
  });

  // Перехватываем запросы для добавления токена авторизации
  $api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("access_token");
      // console.log(token);
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  // Перехватываем ответы для обработки ошибок
  $api.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      // debugger
      console.log("err interceptor");
      const originalConfig = err.config;
      if (originalConfig.url !== "/" && err.response) {
        if (err.response.status === 401 && !originalConfig._retry) {
          console.log("token died");
          originalConfig._retry = true; // Отмечаем, что повторный запрос будет выполнен
          try {
            console.log("try refresh");

            const response = await refresh();
            console.log("refresh", response);

            localStorage.setItem("access_token", response.access_token);

            originalConfig.headers["Authorization"] =
              "Bearer " + response.access_token;

            return $api(originalConfig);
          } catch (_error) {
            console.log(_error, "Рефреш истек");
            localStorage.removeItem("access_token");

            if (window.location.pathname !== "/") window.location.assign("/");
          }
        }
      }

      // Возвращаем отклоненное обещание с ошибкой
      // window.location.href = '/';
      return Promise.reject(err);
    },
  );
  return $api;
};

export default useHttp;

const Eye = (props) => (
  <svg 
    width="25" 
    height="25" 
    viewBox="0 0 25 25" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="eye">
      <g id="Icon">
        <path d="M2.97566 13.2132C2.83948 12.9975 2.77138 12.8897 2.73327 12.7234C2.70463 12.5985 2.70463 12.4015 2.73327 12.2766C2.77138 12.1103 2.83948 12.0025 2.97566 11.7868C4.10107 10.0048 7.45094 5.5 12.5559 5.5C17.6609 5.5 21.0108 10.0048 22.1362 11.7868C22.2724 12.0025 22.3405 12.1103 22.3786 12.2766C22.4073 12.4015 22.4073 12.5985 22.3786 12.7234C22.3405 12.8897 22.2724 12.9975 22.1362 13.2132C21.0108 14.9952 17.6609 19.5 12.5559 19.5C7.45094 19.5 4.10107 14.9952 2.97566 13.2132Z" 
          stroke="#667085" 
          strokeWidth="2" 
          strokeLinecap="round" 
          strokeLinejoin="round"
        />
        <path d="M12.5559 15.5C14.2128 15.5 15.5559 14.1569 15.5559 12.5C15.5559 10.8431 14.2128 9.5 12.5559 9.5C10.8991 9.5 9.55595 10.8431 9.55595 12.5C9.55595 14.1569 10.8991 15.5 12.5559 15.5Z" 
          stroke="#667085" 
          strokeWidth="2" 
          strokeLinecap="round" 
          strokeLinejoin="round"
        />
      </g>
    </g>
  </svg>
);

export default Eye;

import { combineReducers } from "@reduxjs/toolkit";
import { TState } from "./types";
import { camerasReducer } from "./slices/cameras";
import { errorsReducer } from "./slices/errors";

const appReducer = combineReducers({
  cameras: camerasReducer,
  errors: errorsReducer,
});

const rootReducer = (state: TState | undefined, action: any) => {
  if (action.type === "user/logout") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;

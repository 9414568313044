import { useState } from "react";
import { COMMON_ERROR_TEXT } from "../constants";

type TError = keyof typeof COMMON_ERROR_TEXT | false | null;
// todo instead of it u can usr rtk query error loading states
const useFetch = (
  request: any,
  requestName: string,
  onError?: any,
  initialData?: any,
) => {
  const [data, setData] = useState(initialData);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<TError>(null);

  const fetchData = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await request();
      setData(response);
    } catch (err: any) {
      console.log(requestName, err);
      setError(
        err.response && !Array.isArray(err.response?.data.detail)
          ? err.response?.data.detail
          : (err.code ?? err.message),
      );
      onError && onError(err);
    } finally {
      setLoading(false);
    }
  };
  return { data, loading, error, fetchData, setError };
};
export default useFetch;

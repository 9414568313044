import TooltipTriangle from "../../assets/icons/tooltip-triangle";
import { classNames } from "../../utils/classNames";
import s from "./Tooltip.module.css";
type TTooltip = {
  title: string;
  color?: "white" | "black";
  size?: "small" | "large";
  pointerPosition?:
    | "bottom-centered"
    | "top-centered"
    | "left"
    | "right"
    | "bottom-left"
    | "bottom-right"
    | "top-left"
    | "top-right"
    | "none";
  description?: string;
  width?: string;
  children?: React.ReactNode;
};
const Tooltip = ({
  color = "white",
  size = "large",
  title,
  description,
  pointerPosition = "none",
  width,
  children,
}: TTooltip) => {
  return (
    <div
      className={classNames(s.tooltip, {
        [s[color]]: color,
        [s[size]]: size,
        [`w-[${width}]`]: width,
      })}
    >
      <div className="flex flex-col gap-1">
        <p className="font-semibold">{title}</p>
        {description && <p>{description}</p>}
      </div>
      <div
        className={classNames(s.pointer, {
          [s[pointerPosition]]: pointerPosition,
        })}
      >
        <TooltipTriangle fill={color} />
      </div>
      {children}
    </div>
  );
};

export default Tooltip;

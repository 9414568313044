export enum TVideoSourceSourceType {
  IP_CAMERA = "ip_camera",
  ONVIF = "onvif",
}
export enum TVideoSourceProtocol {
  RTSP = "rtsp",
  HTTP = "http",
  RTMP = "rtmp",
}
export type TVideoSource = {
  source_type: TVideoSourceSourceType;
  protocol: TVideoSourceProtocol | string;
  domain: string;
  title?: string;
  username?: string;
  password?: string;
  port?: number;
  path?: string;
  query?: string;
  user_id?: string;
  id?: string;
  url?: string;
};

export type TVideoSourceList = TVideoSource[];

import axios from "axios";
import { API_PREFIX, API_URL } from "./constants";

export const refresh = async () => {
  const { data } = await axios.post(
    `${API_URL}/v1/${API_PREFIX.AUTH}/refresh`,
    null,
    {
      withCredentials: true,
    },
  );
  return data;
};

import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Preloader from "../../ui/Preloader/Preloader";

const AuthLayout = () => {
  const navigate = useNavigate();
  const [preloader, setPreloader] = useState(true);

  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      navigate("/home");
    } else {
      setPreloader(false);
    }
  }, []);

  if (preloader) {
    return <Preloader />;
  }
  return (
    <Outlet />
  );
};

export default AuthLayout;

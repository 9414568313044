import { ConfirmEmail } from "../../pages/auth/ConfirmEmail";
import { EmailConfirmed } from "../../pages/auth/EmailConfirmed";
import { Error } from "../../pages/auth/Error";
import { Login } from "../../pages/auth/Login";
import { NewPassword } from "../../pages/auth/NewPassword";
import { PasswordChanged } from "../../pages/auth/PasswordChanged";
import { RecoveryMessage } from "../../pages/auth/RecoveryMessage";
import { ResetPassword } from "../../pages/auth/ResetPassword";
import { SignUp } from "../../pages/auth/SignUp";
import { Cameras } from "../../pages/contents/Cameras";
import { Main } from "../../pages/contents/Main";

export const ROUTE_LAYOUTS = {
  AUTH: "AUTH",
  CONTENT: "CONTENT",
};
export const CHILDREN_ROUTES = {
  [ROUTE_LAYOUTS.AUTH]: [
    {
      path: "/",
      element: <Login />,
    },
    {
      path: "/auth/sign-up",
      element: <SignUp />,
    },
    {
      path: "/auth/reset-password",
      element: <ResetPassword />,
    },
    {
      path: "/auth/reset-password-sent",
      element: <RecoveryMessage />,
    },
    {
      path: "/auth/try-again",
      element: <Error />,
    },
    {
      path: "/auth/new-password",
      element: <NewPassword />,
    },
    {
      path: "/auth/email-confirmed",
      element: <EmailConfirmed />,
    },
    {
      path: "/auth/confirm-email",
      element: <ConfirmEmail />,
    },
    {
      path: "/auth/password-changed",
      element: <PasswordChanged />,
    },
  ],
  [ROUTE_LAYOUTS.CONTENT]: [
    {
      path: "/home",
      element: <Main />,
    },
    {
      path: "/home/cameras",
      element: <Cameras />,
    },
  ],
};

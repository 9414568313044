import Wifi from "../../../../assets/icons/wifi";
import { classNames } from "../../../../utils/classNames";
import TitleContainer from "../../../../components/TitleContainer/TitleContainer";
import Info from "../../../../assets/icons/info";
import s from "./FirstStep.module.css";
import FooterButtons from "../FooterButtons/FooterButtons";
import { TVideoSourceSourceType } from "../../../../api/types/videoSource";
import { TSourceType } from "../CameraAddition/CameraAddition";
import { memo } from "react";

type TFirstStep = {
  connectionType: TSourceType;
  setConnectionType: (connectionType: TVideoSourceSourceType) => void;
  onClose: () => void;
  onProceed: () => void;
};
const FirstStep = memo(
  ({ connectionType, setConnectionType, onClose, onProceed }: TFirstStep) => {
    const connectionTypes = [
      {
        id: TVideoSourceSourceType.IP_CAMERA,
        title: "Подключение по IP",
        description: `
        Подключение камер по IP подходит для как для домашних, 
        так и для профессиональных систем безопасности.
      `,
        icon: <Wifi />,
      },
    ] as const;
    return (
      <>
        <TitleContainer
          size="medium"
          title="Выбор типа подключения камеры"
          description="Выберите наиболее подходящий способ подключения для вашей системы."
        />
        <div className="flex flex-wrap gap-3 pb-40">
          {connectionTypes.map((type) => (
            <button
              className={classNames(s.connectionType, {
                [s.active]: type.id === connectionType,
              })}
              onClick={() => setConnectionType(type.id)}
              key={type.id}
            >
              {type.icon}
              <TitleContainer
                size="xsmall"
                title={type.title}
                description={type.description}
              />
            </button>
          ))}
        </div>
        <FooterButtons
          titleNext="Продолжить"
          titlePrev="Отменить"
          prevOnClick={onClose}
          nextOnClick={onProceed}
          disabledNext={!connectionType}
          infoIcon={<Info />}
          infoText={`Мы активно работаем над улучшением нашего сервиса и в ближайшее время
          добавим новые методы добавления камер для вашего удобства.`}
        />
      </>
    );
  },
);

export default FirstStep;

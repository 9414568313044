import React from "react";
import Button from "../../../../ui/Button/Button";
import s from "./FooterButtons.module.css";
import Wheel from "../../../../assets/icons/wheel";

type TFooterButtons = {
  prevOnClick?: () => void;
  nextOnClick: () => void;
  loadingNext?: boolean;
  disabledNext?: boolean;
  titleNext: string;
  titlePrev?: string;
  infoIcon?: React.ReactNode;
  infoText?: React.ReactNode;
  noInfo?: boolean;
};
const FooterButtons = ({
  prevOnClick,
  nextOnClick,
  loadingNext,
  disabledNext,
  titleNext,
  titlePrev,
  infoIcon,
  infoText,
  noInfo = false,
}: TFooterButtons) => {
  return (
    <footer className={s.container}>
      {!noInfo && (
        <div className={s.info}>
          {infoText ? (
            <>
              {infoIcon}
              <p className="text-xs text-gray-600">{infoText}</p>
            </>
          ) : (
            <>
              <Wheel />
              <div>
                <p>Возникли трудности?</p>
                Мы всегда готовы помочь вам в этом процессе.
              </div>
              <div className="ml-auto">
                <Button color="white" size="xsmall" tag="a">
                  Связаться с нами
                </Button>
              </div>
            </>
          )}
        </div>
      )}

      {!noInfo && <div className="divider" />}
      <div className={s.buttonsGroup}>
        <Button
          color="green"
          onClick={nextOnClick}
          disabled={disabledNext}
          size="medium"
          isLoading={loadingNext}
        >
          {titleNext}
        </Button>
        {titlePrev && (
          <Button color="transparent" onClick={prevOnClick} size="medium">
            {titlePrev}
          </Button>
        )}
      </div>
    </footer>
  );
};

export default FooterButtons;

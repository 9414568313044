import React, { useState } from "react";
import { Modal } from "../../../../components/Modal/Modal";
import { TModalContainer } from "../../../../components/ModalContainer/ModalContainer";
import Plus from "../../../../assets/icons/plus";
import s from "./CameraAddition.module.css";
import FirstStep from "../FirstStep/FirstStep";
import SecondStep from "../SecondStep/SecondStep";
import ThirdStep from "../ThirdStep/ThirdStep";
import ForthStep from "../ForthStep/ForthStep";
import FifthStep from "../FifthStep/FifthStep";
import { TVideoSource } from "../../../../api/types/videoSource";

export type TSetStep = React.Dispatch<React.SetStateAction<1 | 2 | 3 | 4 | 5>>;
export type TSourceType = TVideoSource["source_type"] | null;
export const CameraAddition = ({
  onClose,
  open,
}: {
  onClose: TModalContainer["onClose"];
  open: TModalContainer["open"];
}) => {
  const [sourceType, setSourceType] = useState<TSourceType>(null);
  const [step, setStep] = useState<1 | 2 | 3 | 4 | 5>(1);
  const [videoSourceId, setVideoSourceId] = useState<string>("");
  const [cameraName, setCameraName] = useState<string>("");

  const ADDITION_STEPS = {
    1: (
      <FirstStep
        connectionType={sourceType}
        setConnectionType={setSourceType}
        onProceed={() => setStep(2)}
        onClose={onClose}
      />
    ),
    2: (
      <SecondStep
        setStep={setStep}
        sourceType={sourceType!}
        setVideoSourceId={setVideoSourceId}
      />
    ),
    3: <ThirdStep setStep={setStep} videoSourceId={videoSourceId} />,
    4: (
      <ForthStep
        cameraName={cameraName}
        setCameraName={setCameraName}
        setStep={setStep}
      />
    ),
    5: (
      <FifthStep
        onEnd={onClose}
        cameraName={cameraName}
        videoSourceId={videoSourceId}
      />
    ),
  } as const;

  return (
    <Modal
      onClose={onClose}
      width="800px"
      open={open}
      headerComponent={
        <div className={s.headerContainer}>
          <div className={s.plus}>
            <Plus />
          </div>
          <p className="primary-text-m">Добавить новую камеру</p>
        </div>
      }
    >
      {ADDITION_STEPS[step]}
    </Modal>
  );
};
